import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Наші послуги в студії татуювань Півонія
			</title>
			<meta name={"description"} content={"Studio Tattoo Півонія присвячена наданню цілого ряду послуг, адаптованих до їх мистецького бачення та особистого стилю. "} />
			<meta property={"og:title"} content={"Наші послуги | Наші послуги в студії татуювань Півонія"} />
			<meta property={"og:description"} content={"Studio Tattoo Півонія присвячена наданню цілого ряду послуг, адаптованих до їх мистецького бачення та особистого стилю. "} />
			<meta property={"og:image"} content={"https://zakarpattiazones.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://zakarpattiazones.live/img/086e66dddefc80493bf1c4e367cdba10.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://zakarpattiazones.live/img/086e66dddefc80493bf1c4e367cdba10.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://zakarpattiazones.live/img/086e66dddefc80493bf1c4e367cdba10.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://zakarpattiazones.live/img/086e66dddefc80493bf1c4e367cdba10.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://zakarpattiazones.live/img/086e66dddefc80493bf1c4e367cdba10.png"} />
			<meta name={"msapplication-TileImage"} content={"https://zakarpattiazones.live/img/086e66dddefc80493bf1c4e367cdba10.png"} />
		</Helmet>
		<Components.Header />
		<Section background="#c0c0c0" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text as="h1" margin="12px 0" font="--headline2" md-font="--headline3">
							Послуги
						</Text>
						<Text
							as="p"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Студія татуювання присвячена наданню цілого ряду послуг, адаптованих до їх мистецького бачення та особистого стилю. Наші кваліфіковані художники тут, щоб направляти вас на кожному кроці вашої поїздки на татуювання, від початкової концепції до остаточного шедевра.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://zakarpattiazones.live/img/4.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://zakarpattiazones.live/img/5.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://zakarpattiazones.live/img/6.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Наші послуги татуювання
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Персоналізований дизайн татуювань
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Співпрацюйте з нашими художниками, щоб створити унікальний дизайн, який відображає вашу індивідуальність.Незалежно від того, чи це символічний твір, абстрактне мистецтво чи портрет, ми даємо життя вашому баченню.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Покриття та ретушування
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Перетворіть стару татуювання на новий витвір мистецтва.Наші експерти спеціалізуються на маскуванні та ретушуванні, пропонуючи творчі рішення для оновлення або зміни існуючої фарби.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Колір і чорно -сірі татуювання
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Виберіть з яскравих кольорових татуювань або позачасового чорного та сірого елегантності.Наші художники відмінні в обох, забезпечуючи глибину, контраст і ясність у кожному творі.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Стильний
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Від традиційних, племінних та японських малюнків до реалізму, геометричного та мінімалістичного - наша диверсифікована команда може зустріти широкий спектр стилів татуювання.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Консультаційні послуги
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Призначити індивідуальну зустріч, щоб обговорити свої ідеї, отримати консультації щодо розміщення та розміру та дізнайтеся більше про пізніший процес допомоги.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Гарантія охорони здоров'я та безпеки
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Ми надаємо пріоритет вашій безпеці, дотримуючись суворих стандартів охорони здоров’я, використовуючи стерильне обладнання та надаючи чіткі вказівки щодо подальшої допомоги.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});